<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? '' : ''">
        <v-row justify="center" align="center">

            <v-col cols="12" justify="center" align="center" v-if="checking">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
        
            <v-col cols="12" md="8" lg="6" v-else>
                <v-card flat>

                    <v-card-text class="pb-8">

                        <div class="text-center">
                            <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                                large>mdi-crown</v-icon>
                            <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Unlock
                                7 Days of Premium Benefits </div>
                        </div>

                        <div class="pa-4 mt-4 mb-8"
                            style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                            <v-row>
                                <v-col cols="1" class="pb-0">
                                    <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                                </v-col>
                                <v-col cols="11" class="pl-4 pb-0">Seamless communication</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" class="pb-0">
                                    <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                                </v-col>
                                <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" class="pb-0">
                                    <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                                </v-col>
                                <v-col cols="11" class="pl-4 pb-0">Direct connect credit</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" class="pb-0">
                                    <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                                </v-col>
                                <v-col cols="11" class="pl-4 pb-0">Stand out with our Premium tag</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" class="pb-0">
                                    <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                                </v-col>
                                <v-col cols="11" class="pl-4">Priority placement in search results</v-col>
                            </v-row>
                        </div>


                        <v-row>
                            <v-col cols="12" class="d-flex align-center justify-center">
                                <v-item>

                                    <v-sheet flat class="pa-2 ">
                                        <div class="text-center ">
                                            Unlock all the premium features for just
                                        </div>
                                        <div class="text-center text-h6 pa-3 font-weight-bold"
                                            v-if="$store.getters.userDetails.country == 'IN'">
                                            &#8377; 199.00
                                        </div>
                                        <div class="text-center text-h6 pa-3 font-weight-bold" v-else>
                                            $4.99
                                        </div>
                                        <div class="text-center">
                                            <v-btn color="success" :disabled="!valid || expired" @click="checkOut">Get 7-Day Premium Access Now</v-btn>
                                        </div>
                                        <div class="text-center text-caption pa-3 grey--text" v-show="valid && !expired">
                                            <span>This offer will expire in {{ time_left }}</span>
                                        </div>

                                        <div class="text-center text-caption pa-3 red--text" v-show="valid && expired">
                                            <span>This offer has been expired</span>
                                        </div>

                                        <div class="text-center text-caption pa-3 red--text" v-show="!valid && expired">
                                            <span>This offer url is invalid</span>
                                        </div>

                                    </v-sheet>

                                </v-item>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <div class="text-caption pt-6"><b>Note:</b> Price is listed in USD and will be converted to the relevant local currency during checkout.</div>
                       
                    </v-card-text>

                </v-card>

                <v-snackbar v-model="snackbar">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    name: 'OneWeekPremium',
    props: ['xid'],

    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        }
        else {
            this.validateOffer();
            //validate the id in the url
            //If valid, show the button to trigger checkout
            //If not, show expired
        }

    },

    data() {
        return {

            processing: false,
            snackbar: false,
            snackbar_text: '',
            checking: true,
            valid: true,
            expired: false,
            time_left: '24 hours',




        }
    },

    methods: {

        validateOffer() {


            this.$store.dispatch('validateOneWeekPremium', this.xid)
            .then((resp) => {
                        if (resp.data.message == 'Offer Active') {
                            this.valid = true;
                            this.expired = false;
                            this.time_left = resp.data.expire_in;
                        }
                        else if (resp.data.message == 'Offer Expired') {
                            this.valid = true;
                            this.expired = true;
                        }
                        else if(resp.data.message == 'Invalid URL') {
                            this.valid = false;
                            this.expired = true;
                        }
                        this.checking = false;
                    })
            .catch(err => {

            });

    },


        showPremium() {
            this.close();
            this.$store.dispatch('showPremiumPop', true);
        },


        checkOut() {

            var s =
            {
                //Reset the cart session  to remove everything added to the cart previously.
                'reset': true,
                "paymentContact": {
                    "email": this.$store.getters.userDetails.email,
                    "country": this.$store.getters.userDetails.country || 'US'
                },
                //Define the product path(s) and the quantity to add.
                'products': [
                    {
                        'path': 'premium-membership-w',
                        'quantity': 1
                    }
                ],
                'checkout': true,
                "tags": {
                    "customer": this.$store.getters.userDetails.xid
                },
            }

            window.gtag('event', 'purchase_init_fastspring', {
                event_category: 'Purchase',
                event_label: 'One Week Premium',
            });

            window.fastspring.builder.push(s);


        },

    }
}
</script>