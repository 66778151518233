import Vue from 'vue'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'


export default {
    state: {
        searchResults: [],
        conversations: [],
        favorites: [],
        conversationsLoaded: false,
        premiumPop: false,
        premiumPopStripe: false,
        directConnectPop: false,
        messageCreditPop: false,
        verifyContactPop: false,
        verifyProfilePop: false,
        categoryPop: false,
        connections:[],
        notifications:[],
        chatModel: false,
        contactDialog: false,
        activeChatIdentifier: null,
        triggerAdopterQuestionnaire: false,
    },
    getters: {
        searchResults: state => state.searchResults,
        conversations: state => state.conversations,
        notifications: state => state.notifications,
        conversationsLoaded: state => state.conversationsLoaded,
        favorites: state => state.favorites,
        premiumPop: state => state.premiumPop,
        premiumPopStripe: state => state.premiumPopStripe,
        verifyContactPop: state => state.verifyContactPop,
        verifyProfilePop: state => state.verifyProfilePop,
        categoryPop: state => state.categoryPop,
        directConnectPop: state => state.directConnectPop,
        messageCreditPop: state => state.messageCreditPop,
        chatModel: state => state.chatModel,
        activeChatIdentifier: state => state.activeChatIdentifier,
        contactDialog: state => state.contactDialog,
        triggerAdopterQuestionnaire: state => state.triggerAdopterQuestionnaire,
        isConnection: (state) => (user_id) =>{
            return state.connections.includes(user_id);
        },
        user: (state, rootGetters)=> {
            return rootGetters.userDetails;
        },
        getConversation: (state) => (conv_id) =>{
            return state.conversations.find(
                conv => conv.xid === conv_id
            );
        },
        getPetConversations: (state) => (pet_id) =>{
            return state.conversations.filter(
                conv => (
                    (conv.parties[0].pet && conv.parties[0].pet.xid == pet_id) || (conv.parties[1].pet && conv.parties[1].pet.xid == pet_id))
            );
        },
        getUnreadMessages: (state) => (pet_id) =>{
            let convs = state.conversations.filter(
                conv => (
                    (conv.parties[0].pet && conv.parties[0].pet.xid == pet_id) || (conv.parties[1].pet && conv.parties[1].pet.xid == pet_id))
            );

            return convs.reduce((total, obj) => obj.unread_messages_count + total,0)
        }, 
        getTotalMessages: (state) => {
            return state.conversations.reduce((total, obj) => obj.unread_messages_count + total,0)
        }, 
        unreadNotificationsCount: (state) => {
            let unreadNotifications =  state.notifications.filter((notif) => notif.read_at == null)
            return unreadNotifications.length
        },
    },
    mutations: {
        resetAppState(state)
        {
            state.searchResults = []
            state.conversations = []
            state.favorites = []
            state.conversationsLoaded = false
            state.premiumPop = false
            state.premiumPopStripe = false
            state.verifyContactPop = false
            state.verifyProfilePop = false
            state.categoryPop = false
            state.directConnectPop = false
            state.messageCreditPop = false
            state.connections = []
            state.notifications = []
            state.contactDialog = false
        },
        setPremiumPop(state,payload)
        {
            state.premiumPop = payload;
        },
        setPremiumPopStripe(state,payload)
        {
            state.premiumPopStripe = payload;
        },
        setVerifyContactPop(state,payload)
        {
            state.verifyContactPop = payload;
        },
        setVerifyProfilePop(state,payload)
        {
            state.verifyProfilePop = payload;
        },
        setCategoryPop(state,payload)
        {
            state.categoryPop = payload;
        },
        setDirectConnectPop(state,payload)
        {
            state.directConnectPop = payload;
        },
        setMessageCreditPop(state,payload)
        {
            state.messageCreditPop = payload;
        },
        setChatModel(state,payload)
        {
            state.chatModel = payload;
        },
        setActiveChatIdentifier(state, payload) 
        {
            state.activeChatIdentifier = payload;
        },
        setContactDialog(state,payload)
        {
            state.contactDialog = payload;
        },
        setSearchResults(state, {payload, getters}) {

            /*let blocked=  getters.user.meBlocked ? getters.user.meBlocked.map(o => {
                return o.bbid;
            }) : [];

            payload = payload ? payload.filter(o => {
                if (!blocked.includes(o.user.xid)) {
                    return o;
                }
            }) : [];*/
            state.searchResults = [...state.searchResults, ...payload]
        },

        clearSearchResults(state)
        {
            state.searchResults = [];
        },

        setNotifications(state, payload) {
            state.notifications = payload;
        },

        updateNotifications(state, payload) {
            let idx = state.notifications.findIndex((obj => obj.id == payload.id));
            if(idx!==-1)
            {
                state.notifications[idx].read_at = new Date();
            }
        },

        updateAllNotifications(state, payload) {
            state.notifications.forEach((item, idx) => {
                state.notifications[idx].read_at = new Date();
            });
        },

        setConversations(state, payload) {
            state.conversations = payload;
            state.conversationsLoaded = true;
        },

        addConversation(state, payload) {
            state.conversations = [payload, ...state.conversations]
        },

        addMessage(state, payload) {
            let idx = state.conversations.findIndex((obj => obj.xid == payload.cid));
            if(idx!==-1)
            {
                state.conversations[idx].messages.push(payload.message);
                state.conversations[idx].updated_at = payload.message.created_at;

                //var conv = state.conversations.splice(idx,1);
                //state.conversations.unshift(conv[0]);
            }

            
        },

        updateConversationMessage(state, payload) {
            let idx = state.conversations.findIndex((obj => obj.xid == payload.cid));
            if(idx!==-1)
            {
                state.conversations[idx].messages = payload.messages;
            }
        },

        updateConversationUnread(state, payload) {
            let idx = state.conversations.findIndex((obj => obj.xid == payload.cid));
            if(idx!==-1)
            {
                let new_cnt = state.conversations[idx].unread_messages_count - payload.count;
                state.conversations[idx].unread_messages_count = (new_cnt < 0)? 0 : new_cnt;
            }
        },

        setFavorites(state, payload) {
            state.favorites = payload;
        },

        setConnections(state, payload) {
            state.connections = payload;
        },

        updateFavorite(state, payload) {
            //remove the pet from the favorite list
            //negate the isFavorite status in search results

            state.favorites = state.favorites.filter(function( item ) {
                return item.xid !== payload.pet_xid;
              });

            let idx = state.searchResults.findIndex((obj => obj.xid == payload.pet_xid));
            if(idx!==-1)
            {
                state.searchResults[idx].is_favorite = !state.searchResults[idx].is_favorite;
            }

        },

        sortConversations(state)
        {
           state.conversations =  _.orderBy(state.conversations,'updated_at','desc')
        },

        setTriggerAdopterQuestionnaire(state, payload) {
            console.log('mutated');
            state.triggerAdopterQuestionnaire = payload;
          },
    },
    actions: {

        sortConversations({ commit }) {
            commit("sortConversations");
        },

        clearSearchResults({ commit }) {
            commit("clearSearchResults");
        },

        showPremiumPop({ commit }, show) {
            commit("setPremiumPop", show);
        },

        showPremiumPopStripe({ commit }, show) {
            commit("setPremiumPopStripe", show);
        },

        showVerifyContactPop({ commit }, show) {
            commit("setVerifyContactPop", show);
        },

        showVerifyProfilePop({ commit }, show) {
            commit("setVerifyProfilePop", show);
        },

        showCategoryPop({ commit }, show) {
            commit("setCategoryPop", show);
        },

        showDirectConnectPop({ commit }, show) {
            commit("setDirectConnectPop", show);
        },

        showMessageCreditPop({ commit }, show) {
            commit("setMessageCreditPop", show);
        },

        showChatModel({ commit }, show) {
            commit("setChatModel", show);
        },

        setActiveChatIdentifier({ commit }, payload) {
            commit('setActiveChatIdentifier', payload);
        },

        showContactDialog({ commit }, show) {
            commit("setContactDialog", show);
        },

        getFavorites({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/favorites")
                    .then(resp => {
                        //console.log(resp.data)
                        commit("setFavorites", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        markFavorite({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/favorites", data)
                    .then(resp => {
                        commit("updateFavorite", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        search({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/matches", data)
                    .then(resp => {
                        let payload = resp.data;
                        commit("setSearchResults", { payload, getters} );
                        resolve(resp.data.length);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        searchAdoptions({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/adoptions", data)
                    .then(resp => {
                        let payload = resp.data;
                        commit("setSearchResults", { payload, getters} );
                        resolve(resp.data.length);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        searchAvailable({ commit, getters}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/available", data)
                    .then(resp => {
                        let payload = resp.data;
                        commit("setSearchResults", { payload, getters});
                        resolve(resp.data.length);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        searchAdopters({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/adopters", data)
                    .then(resp => {
                        let payload = resp.data;
                        commit("setSearchResults", { payload, getters} );
                        resolve(resp.data.length);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        searchBuyers({ commit,getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/buyers", data)
                    .then(resp => {
                        let payload = resp.data;
                        commit("setSearchResults", { payload, getters} );
                        resolve(resp.data.length);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getConversations({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/conversations")
                    .then(resp => {
                        let conversations = resp.data;
                        commit("setConversations", conversations);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getConversationById({ commit, dispatch }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/conversation/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getConversationBy({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/conversation/by", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        newConversation({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/conversation", data)
                    .then(resp => {
                        let conversation = resp.data;
                        commit("addConversation", conversation);
                        resolve(conversation);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        sendPictureMessage({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/picture-message", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        let message = resp.data;
                        /* dispatch("getConversations").then(resp => {
                            commit("setConversations", resp.data);
                            resolve();
                        }); */
                        commit("addMessage", {cid:data.get('cid'), message});
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        newPictureConversation({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/picture-conversation", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        let conversation = resp.data;
                        commit("addConversation", conversation);
                        resolve(conversation);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        sendMessage({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/message", data)
                    .then(resp => {
                        let message = resp.data;
                        /* dispatch("getConversations").then(resp => {
                            commit("setConversations", resp.data);
                            resolve();
                        }); */
                        commit("addMessage", {cid:data.cid, message});
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getMessages({ commit, dispatch }, cid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/messages/"+cid)
                    .then(resp => {
                        let messages = resp.data;
                        commit("updateConversationMessage", {cid, messages});
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        markRead({ commit, dispatch }, cid) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/read/"+cid)
                    .then(resp => {
                        let count = resp.data
                        commit("updateConversationUnread", {cid, count});
                        dispatch("getMessages", cid).then(resp => {
                            resolve();
                        });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        archiveConversation({ commit, dispatch }, cid) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/conversation/archive/"+cid)
                    .then(() => {
                        dispatch("getConversations").then(resp => {});
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        unarchiveConversation({ commit, dispatch }, cid) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/conversation/archive/"+cid)
                    .then(() => {
                        dispatch("getConversations").then(resp => {});
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addConnection({ commit, dispatch, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/connects/add", data)
                    .then(resp => {
                        if(!getters.isConnection(data.xid))
                        {
                            dispatch("profile");
                        }
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getConnections({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/connects")
                    .then(resp => {
                        //console.log(resp.data)
                        commit("setConnections", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getNotifications({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/notifications")
                    .then(resp => {
                        let notifications = resp.data;
                        commit("setNotifications", notifications);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        markNotificationRead({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/notifications/read/"+id)
                    .then(resp => {
                        let count = resp.data
                        commit("updateNotifications", {id});
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        markAllNotificationsRead({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/notifications/read-all")
                    .then(resp => {
                        let count = resp.data
                        commit("updateAllNotifications");
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        logView({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/log-view", data)
                    .then(resp => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        reportScam({ commit, dispatch, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/scam/report", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        blockUser({ commit, dispatch, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/user/block", data)
                    .then(resp => {
                        dispatch("showChatModel", false);
                        dispatch("getConversations").then(respo => {});
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        unblockUser({ commit, dispatch, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/user/unblock", data)
                    .then(resp => {
                        dispatch("showChatModel", false);
                        dispatch("getConversations").then(respo => {});
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        sendQuickReply({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/quick-reply", data)
                    .then(resp => {
                        let res = resp
                        resolve(res)
                            
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        submitFollow({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/sm-follow", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        unlockMessage({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/unlock-message", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        publicSearch({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/public-search", data)
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        publicSearchAdopters({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/public-search-adopters", data)
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        triggerAdopterQuestionnaire({ commit }, value) {
            commit('setTriggerAdopterQuestionnaire', value);
          },
        
    }
}